import { Card } from 'primereact/card'
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea';
import emailjs from 'emailjs-com'
import React, { useRef, useState } from 'react'
import { Divider } from 'primereact/divider';
import { Toast } from 'primereact/toast';
import swal from 'sweetalert';
import Testemonial from './Testemonial';
import { FaGithubAlt, FaGithubSquare, FaLinkedinIn } from 'react-icons/fa';


function FormMessage() {

    const [message, setmessage] = useState({
        name: "",
        email: "",
        subject: "",
        compose: "",
    });
    const toast = useRef();
    const [loading, setLoading] = useState(false);
    const form = useRef();


    const handleInput = (e) => {
        e.persist();
        setmessage({ ...message, [e.target.name]: e.target.value })
    }

    const SendEmail = (e) => {
        e.preventDefault();
        setLoading(true)
        emailjs.sendForm('service_2qu1gwd', 'template_w6dap05', form.current, 'zmzTkDcRM_4xv9LHf').then((result) => {
            if (result.status === 200) {
                swal("Success", 'Message Sent', 'success');
                document.getElementById("form").reset();
                setLoading(false)
            }
        }, (error) => {
            if (error.status === 412) {
                swal("Error", "Server Error", 'error');
                setLoading(false)

            }
        })
    }

    return (
        <div className='' id='message'>
            <Toast ref={toast} />
            <div className="row justify-content-space align-items-center">
                <div className="col-lg-6 mb-3">
                    <Divider>
                        <h4 className='text-white'>Awards & Certificate</h4>
                    </Divider>
                    <ul className='details'>
                        <li className='mb-1'><span className='text-parag'>- <small>1 Year Developer Lead in Google Developer Student Club at Father Saturnino Urios University. SY (2021-2022)</small></span></li>
                        <li className='mb-1'><span className='text-parag'>- <small>2 Years Comelec Commissioner at Father Saturnino Urios University.</small></span></li>
                        <li className='mb-1'><span className='text-parag'>- <small>SQL for Data Science at University of California, Davis in Coursera.</small></span></li>
                        <li className='mb-1'><span className='text-parag'>- <small>Certificate From the  Civil Service Commission.</small></span></li>
                    </ul>
                    <br />
                    <Divider className='mb-3'>
                        <h4 className='text-white'>Interest</h4>
                    </Divider>
                    <p className='text-parag'>As a developer, I spend a lot of my free time discovering and researching the most recent developments in web development and game development. I also enjoy playing sports such as basketball, volleyball, and any outdoors activity.</p>
                </div>
                <div className="col-lg-6 mb-3">
                    <h4 className='text-white'>Drop Message</h4>
                    <form onSubmit={SendEmail} ref={form} id="form">
                        {/* <Card className='bg-transparent'> */}
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <InputText required keyfilter={/^[^#<>*!]+$/} name="name" onChange={handleInput} className="w-100" placeholder='Name' />
                            </div>
                            <div className="col-md-6 mb-3">
                                <InputText required keyfilter={"email"} name="email" onChange={handleInput} className="w-100" placeholder='Email' />
                            </div>
                            <div className="col-md-12 mb-3">
                                <InputText required keyfilter={/^[^#<>*!]+$/} name="subject" onChange={handleInput} className="w-100" placeholder='Subject' />
                            </div>
                            <div className="col-md-12 mb-3">
                                <InputTextarea required placeholder='Compose Message' name='compose' onChange={handleInput} className='w-100' rows={5} cols={30} />
                            </div>
                            <div className="container">
                                <Button loading={loading} label='Submit' icon="pi pi-fw pi-send" onChange={handleInput} iconPos='left' className='p-button-sm p-button-info'></Button>
                            </div>
                            {/* <small className='text-white'>NOTE: You can drop a message here.</small> */}
                        </div>
                        {/* </Card> */}
                    </form>
                </div>
                <Divider></Divider>
                <Testemonial />
                <Divider></Divider>
                {/* <h4 className='text-white'>Social Links</h4> */}
                    {/* <h5 className='text-gray-400'>Social Links</h5> */}
                <div className="mt-4" id='icon_list'>
                    <li>
                        <a target='_blank' href="https://www.linkedin.com/in/georgie-r-7795b61b7/"><FaLinkedinIn color='#0077B5' size={30} /></a>
                    </li>
                    <li>
                        <a target='_blank' href="https://github.com/grecabo123"><FaGithubSquare size={35} color='gray' /></a>
                    </li>
                </div>
            </div>
        </div>
    )
}

export default FormMessage